import { StyledEngineProvider, ThemeProvider } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import Firebase, { FirebaseContext } from './service/Firebase';
import * as serviceWorker from './serviceWorker';
import { configureStore } from './store';
import { theme } from './theme';
import { Suspense } from 'react';
import Loading from './views/Loading';

const store = configureStore();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StoreProvider store={store}>
    <FirebaseContext.Provider value={new Firebase()}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
              <Suspense fallback={<Loading />}>
                <App />
              </Suspense>
            </BrowserRouter>
          </LocalizationProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </FirebaseContext.Provider>
  </StoreProvider>
);

serviceWorker.unregister();
