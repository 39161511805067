import app from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';
import 'firebase/compat/database';
import axios from 'axios';

// const config = {
//   apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
//   projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID
// };

const config = {
  apiKey: 'AIzaSyDogBJHSMScZK5PHZVcuHGclsmToHiUo6E',
  authDomain: 'nubi-9f5f0.firebaseapp.com',
  databaseURL: 'https://nubi-9f5f0.firebaseio.com',
  projectId: 'nubi-9f5f0',
  storageBucket: 'nubi-9f5f0.appspot.com',
  messagingSenderId: '290977877986',
  appId: '1:290977877986:web:5410e815e2e40b12cf73bb',
  measurementId: 'G-3SZPVJ9RMX'
};

const java_url = 'https://api.tibi.mn/lms';
// const java_url = 'http://18.166.176.204:8081/api';
const API_URL = 'https://api.tibi.mn';

class Firebase {
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.realdb = app.database();
  }

  createMealPlanGroupName = async () => {
    // const token = await this.auth.currentUser.getIdToken()
    return 'a';
  };

  doSignInWithCustomToken = async (token) => {
    return this.auth.signInWithCustomToken(token);
  };

  doSignOut = () => this.auth.signOut();

  authSignIn = async (name, password) => {
    const url = '/login';
    return axios.post(API_URL + url, { username: name, password });
  };

  // lmsLogin = async () => {
  //   const url = '/auth/login';
  //   return axios.post(java_url + url, { username: "testuser", password: "Bodygram12@" });
  // };

  stateSessionPersist = async () => {
    return this.auth.setPersistence('session');
  };

  getUserRegisteredRecords = async (userUid) => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.get(API_URL + '/customer/' + userUid + '/records', {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  createIngredient = async (data, image, imagesToUpload, blocks) => {
    const token = await this.auth.currentUser.getIdToken();
    const req = new FormData();
    console.log(image);
    if (image !== null) req.append('image', image, image.name);
    imagesToUpload.map((image) => {
      return req.append('images', image, image.name);
    });
    req.append('blocks', JSON.stringify(blocks));
    req.append('data', JSON.stringify(data));
    return axios.post(API_URL + '/ingredient', req, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  getIngredients = async () => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.get(API_URL + '/ingredient', {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  // getLMS = async () => {
  //   // const token = await this.auth.currentUser.getIdToken();
  //   return axios.get(
  //     API_URL1 + '/promos'
  //     // , {
  //     //   headers: { Authorization: 'Bearer ' + token }
  //     // }
  //   );
  // };

  getFromDatabase = async (menuId) => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.get(API_URL + `/menus/${menuId}`, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  updateIngredient = async (data, image, imagesToUpload, blocks) => {
    const token = await this.auth.currentUser.getIdToken();
    const req = new FormData();
    console.log(image);
    if (image !== null) req.append('image', image, image.name);
    imagesToUpload.map((image) => {
      return req.append('images', image, image.name);
    });
    req.append('blocks', JSON.stringify(blocks));
    req.append('data', JSON.stringify(data));
    // console.log(image);
    return axios.put(API_URL + '/ingredient', req, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  deleteIngredient = async (id) => {
    // const token = await this.auth.currentUser.getIdToken();
    // return axios.delete("/ingredient", {headers: {"Authorization": "Bearer " + token}, data: {id: 9}});
  };

  createFreeFood = async (image, data) => {
    const token = await this.auth.currentUser.getIdToken();
    const req = new FormData();
    if (image !== null) req.append('image', image, image.name);
    req.append('data', JSON.stringify(data));
    return axios.post(API_URL + '/free_foods', req, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  getFreeFoods = async (page, category) => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.get(
      API_URL +
        '/free_foods/page/' +
        page +
        '/' +
        (category !== null ? category : 0),
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  searchFreeFoods = async (query) => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.post(
      API_URL + '/free_foods/search',
      { name: query },
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  editFreeFood = async (id, image, data) => {
    const token = await this.auth.currentUser.getIdToken();
    const req = new FormData();
    if (image !== null) req.append('image', image, image.name);
    req.append('data', JSON.stringify(data));
    return axios.put(API_URL + '/free_foods/' + id, req, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  checkFreeFood = async (query) => {
    const token = await this.auth.currentUser.getIdToken();
    // if(query.length === 0 || query.length > 8)
    //   return Promise.resolve({data: false});
    return Promise.resolve({ data: false });
    // return axios.post("/free_foods/check", {query: query}, {headers: {"Authorization": "Bearer "  + token}});
  };

  deleteFreeFood = async (id) => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.delete(API_URL + '/free_foods/' + id, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  createFood = async (
    image,
    name,
    category,
    foodIngredients,
    imagesToUpload,
    blocks
  ) => {
    // return Promise.reject();
    const token = await this.auth.currentUser.getIdToken();
    const req = new FormData();
    if (image !== null) req.append('image', image, image.name);
    imagesToUpload.map((image) => {
      return req.append('images', image, image.name);
    });
    console.log(foodIngredients);
    req.append('name', JSON.stringify(name));
    req.append('category', JSON.stringify(category));
    req.append('blocks', JSON.stringify(blocks));
    req.append('foodIngredients', JSON.stringify(foodIngredients));
    return axios.post(API_URL + '/foods', req, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  updateFood = async (
    id,
    image,
    name,
    category,
    foodIngredients,
    imagesToUpload,
    blocks
  ) => {
    // return Promise.reject();
    const token = await this.auth.currentUser.getIdToken();
    const req = new FormData();
    if (image !== null) req.append('image', image, image.name);
    imagesToUpload.map((imageU) => {
      return req.append('images', imageU, imageU.name);
    });
    req.append('name', JSON.stringify(name));
    req.append('blocks', JSON.stringify(blocks));
    req.append('category', JSON.stringify(category));
    req.append('foodIngredients', JSON.stringify(foodIngredients));
    // return axios.put("/jflkdjaflkjsalkfjsa;f/" + id, req, {headers: {"Authorization": "Bearer "  + token}});
    return axios.put(API_URL + '/foods/' + id, req, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  getFoods = async (page, category) => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.get(
      API_URL +
        '/foods/page/' +
        page +
        (category !== null ? `/${category}` : ''),
      { headers: { Authorization: 'Bearer ' + token } }
    );
    // return axios.get(
    //   API_URL +
    //     '/foods/page/' +
    //     page +
    //     (category !== null ? `/${category}` : ''),
    //   { headers: { Authorization: 'Bearer ' + token } }
    // );
  };

  getRecentFoods = async () => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.get(API_URL + '/foods/recent', {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  searchFoods = async (query) => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.post(
      API_URL + '/foods/search',
      { query: query },
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };
  checkFood = async (query) => {
    const token = await this.auth.currentUser.getIdToken();
    // if(query.length === 0 || query.length > 8)
    //   return Promise.resolve({data: false});
    // return Promise.resolve({data: false});
    return axios.post(
      API_URL + '/foods/check',
      { query: query },
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  deleteFood = async (id) => {
    const token = await this.auth.currentUser.getIdToken();
    // return Promise.resolve();
    return axios.delete(API_URL + '/foods/' + id, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  deleteCheckFood = async (id) => {
    const token = await this.auth.currentUser.getIdToken();
    // return Promise.resolve({data: {count: 13}});
    return axios.post(
      API_URL + '/foods/delete/check',
      { food_id: id },
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  getFood = async (id) => {
    const token = await this.auth.currentUser.getIdToken();
    // if(id === 1)
    //   return Promise.resolve({data: {
    //       id: 1,
    //       image: "https://www.flavcity.com/wp-content/uploads/2018/06/meatballs-tomato-sauce.jpg",
    //       name: "Бөөрөнхий махтай",
    //       category: 1,
    //       ingredients: [{id: 1, "ingredient_id": 10, "type": false}, {id: 2, "ingredient_id":8, "type":false}],
    //       blocks: []
    //     }});
    // return Promise.resolve({data: {
    //     id: 2,
    //     image: "https://www.flavcity.com/wp-content/uploads/2018/06/meatballs-tomato-sauce.jpg",
    //     name: "Бөөрөнхий",
    //     category: 1,
    //     ingredients: [{id: 3, "ingredient_id": 18, "type": false}, {id: 4, "ingredient_id":21, "type":false}],
    //     blocks: []
    //   }});
    return axios.get(API_URL + '/foods/' + id, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };
  createTeacher = async (id) => {
    const token = localStorage.getItem('token') || '';
    return axios.post(
      java_url + '/teachers',
      { name: 'nhfg' },
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  getTeacher = async (id) => {
    const token = localStorage.getItem('token') || '';
    return axios.get(java_url + '/teachers', {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  createCourse = async (
    image,
    name,
    category,
    courseIngredients,
    imagesToUpload,
    blocks
  ) => {
    // const token = await this.auth.currentUser.getIdToken();
    const token =
      localStorage.getItem('token') ||
      'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZXN0dXNlciIsImlhdCI6MTcxMjEyOTAzOSwiZXhwIjoxNzEyMjE1NDM5fQ.iZ791eY8KF6YQoVePK6B7uclJ6Idi3MD-ajzmhBh5hc';
    const req = new FormData();
    if (image !== null) req.append('image', image, image.name);
    imagesToUpload.map((image) => {
      return req.append('images', image, image.name);
    });
    console.log(courseIngredients);
    req.append('name', JSON.stringify(name));
    req.append('category', JSON.stringify(category));
    req.append('blocks', JSON.stringify(blocks));
    // req.append('courseDetails', JSON.stringify(courseDetails));
    return axios.post(java_url + '/courses', req, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  updateCourse = async (
    id,
    image,
    name,
    courseCategory,
    // courseDetails,
    imagesToUpload,
    blocks
  ) => {
    const token = await this.auth.currentUser.getIdToken();
    const req = new FormData();
    if (image !== null) req.append('image', image, image.name);
    imagesToUpload.map((imageU) => {
      return req.append('images', imageU, imageU.name);
    });
    req.append('name', JSON.stringify(name));
    req.append('blocks', JSON.stringify(blocks));
    req.append('courseCategory', JSON.stringify(courseCategory));
    // req.append('courseDetails', JSON.stringify(courseDetails));
    return axios.put(java_url + '/courses/' + id, req, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  getCourses = async (page, category) => {
    // const token = await this.auth.currentUser.getIdToken();
    const token =
      localStorage.getItem('token') ||
      'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZXN0dXNlciIsImlhdCI6MTcxMzE0NTEwMiwiZXhwIjoxNzEzMjMxNTAyfQ.PFyyLAX-tDl37a9VEdCB0pSMIPCkzz5LMD4qYJz3CIM';
    return axios.get(java_url + '/courses', {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  getRecentCourses = async () => {
    // const token = await this.auth.currentUser.getIdToken();
    const token =
      'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZXN0dXNlciIsImlhdCI6MTcxMzE0NTEwMiwiZXhwIjoxNzEzMjMxNTAyfQ.PFyyLAX-tDl37a9VEdCB0pSMIPCkzz5LMD4qYJz3CIM' ||
      '';
    return axios.get(java_url + '/courses/recent', {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  searchCourses = async (query) => {
    const token =
      localStorage.getItem('token') ||
      'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZXN0dXNlciIsImlhdCI6MTcxMzE0NTEwMiwiZXhwIjoxNzEzMjMxNTAyfQ.PFyyLAX-tDl37a9VEdCB0pSMIPCkzz5LMD4qYJz3CIM';
    return axios.get(
      API_URL + '/courses/search',
      { query: query },
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  checkCourse = async (query) => {
    const token =
      localStorage.getItem('token') ||
      'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZXN0dXNlciIsImlhdCI6MTcxMzE0NTEwMiwiZXhwIjoxNzEzMjMxNTAyfQ.PFyyLAX-tDl37a9VEdCB0pSMIPCkzz5LMD4qYJz3CIM';
    return axios.get(
      API_URL + '/courses/check',
      { query: query },
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  deleteCourse = async (id) => {
    const token =
      localStorage.getItem('token') ||
      'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZXN0dXNlciIsImlhdCI6MTcxMzE0NTEwMiwiZXhwIjoxNzEzMjMxNTAyfQ.PFyyLAX-tDl37a9VEdCB0pSMIPCkzz5LMD4qYJz3CIM';
    return axios.delete(API_URL + '/courses/' + id, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  deleteCheckCourse = async (id) => {
    const token =
      localStorage.getItem('token') ||
      'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZXN0dXNlciIsImlhdCI6MTcxMzE0NTEwMiwiZXhwIjoxNzEzMjMxNTAyfQ.PFyyLAX-tDl37a9VEdCB0pSMIPCkzz5LMD4qYJz3CIM';
    // return Promise.resolve({data: {count: 13}});
    return axios.get(
      API_URL + '/courses/delete/check',
      { course_id: id },
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  getCourseCategories = async (page) => {
    const token =
      localStorage.getItem('token') ||
      'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZXN0dXNlciIsImlhdCI6MTcxMzE0NTEwMiwiZXhwIjoxNzEzMjMxNTAyfQ.PFyyLAX-tDl37a9VEdCB0pSMIPCkzz5LMD4qYJz3CIM';
    // return Promise.resolve({
    //   // data: [
    //   //   { id: 1 }
    //   // ]
    // });
    return axios.get(java_url + '/course-categories', {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  deleteCourseCategory = async (id) => {
    const token = await this.auth.currentUser.getIdToken();
    // return Promise.resolve();
    return axios.delete(API_URL + '/foods/' + id, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  createCourseCategories = async (body) => {
    const token =
      localStorage.getItem('token') ||
      'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZXN0dXNlciIsImlhdCI6MTcxMzkyNTYyNywiZXhwIjoxNzE0MDEyMDI3fQ.sEpoMcGy_n7xw9XnKhfSGNWxDgQnoudiEWeV0YypkDI';
    return axios.post(`${java_url}/course-categories`, body, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  // getTypeCourseCategories = async (id) => {
  //   const token = localStorage.getItem('token') || "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZXN0dXNlciIsImlhdCI6MTcxMjEyOTAzOSwiZXhwIjoxNzEyMjE1NDM5fQ.iZ791eY8KF6YQoVePK6B7uclJ6Idi3MD-ajzmhBh5hc"
  //   return Promise.resolve({
  //     // data: [
  //     //   { id: 1, type_id: 1, course_category_id: 1, order: 1 },
  //     //   { id: 2, type_id: 1, course_category_id: 2, order: 2 },
  //     //   { id: 3, type_id: 1, course_category_id: 3, order: 3 },
  //     //   { id: 4, type_id: 1, course_category_id: 4, order: 4 },
  //     //   { id: 5, type_id: 1, course_category_id: 5, order: 5 }
  //     // ]
  //   });
  // };

  // swapTypeCourseCategory = async (dataX, dataY) => {
  //   const token = localStorage.getItem('token') || "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZXN0dXNlciIsImlhdCI6MTcxMjEyOTAzOSwiZXhwIjoxNzEyMjE1NDM5fQ.iZ791eY8KF6YQoVePK6B7uclJ6Idi3MD-ajzmhBh5hc"
  //   return Promise.resolve({
  //     // data: {
  //     //   dataX: { ...dataX, order: dataY.order },
  //     //   dataY: { ...dataY, order: dataX.order }
  //     // }
  //   });
  // };

  // createTypeCourseCategory = async (data) => {
  //   const token = localStorage.getItem('token') || "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ0ZXN0dXNlciIsImlhdCI6MTcxMjEyOTAzOSwiZXhwIjoxNzEyMjE1NDM5fQ.iZ791eY8KF6YQoVePK6B7uclJ6Idi3MD-ajzmhBh5hc"
  //   return Promise.resolve({
  //     // data: {
  //     //   id: 6,
  //     //   ...data
  //     // }
  //   });
  // }

  // getFoodUsages = async (id) => {
  //   return Promise.resolve({data: [
  //       {name: "Сонголт-1", count: 5, id: 1, calorie: 1200}
  //     ]});
  //   // const token = await this.auth.currentUser.getIdToken();
  //   // return axios.get("/foods/usage/" + id, {headers: {"Authorization": "Bearer "  + token}});
  // }

  setMenuForUser = async (data) => {
    const token = await this.auth.currentUser.getIdToken();
    console.log(data);
    let menuDoc;
    const documents = await this.db
      .collection('menu')
      .where('menuId', '==', data.menuData.id)
      .limit(1)
      .get();
    if (documents.docs.length == 0) {
      menuDoc = await this.db.collection('menu').add({
        calorie: data.menuData.calorie,
        membership: data.menuData.membership,
        menuId: data.menuData.id,
        name: data.menuData.name,
        target: data.menuData.target,
        type: data.menuData.type,
        weight: data.menuData.weight
      });
      await menuDoc.update({ uid: menuDoc.id });
    } else {
      menuDoc = documents.docs[0];
    }
    await this.db.collection('users').doc(data.clientUid).update({
      selectedMenuId: data.menuData.id
    });

    const securityDoc = await this.db
      .doc(`users/${data.clientUid}/security/plan`)
      .get();
    const startDate = new Date();
    const endDate = new Date();
    endDate.setDate(startDate.getDate() + 35);
    if (!securityDoc.exists) {
      this.db
        .collection(`users/${data.clientUid}/security`)
        .doc('plan')
        .set({
          plan: data.menuData.membership === 0 ? 'BASIC' : 'PREMIUM',
          endDate: endDate.getTime(),
          startDate: startDate.getTime()
        });
    } else {
      if (securityDoc.data().endDate < startDate.getTime()) {
        this.db
          .collection(`users/${data.clientUid}/security`)
          .doc('plan')
          .set({
            plan: data.menuData.membership === 0 ? 'BASIC' : 'PREMIUM',
            endDate: endDate.getTime(),
            startDate: startDate.getTime()
          });
      }
    }
  };

  getMenuTypes = async () => {
    console.log('Calling types');
    return axios.get(API_URL + '/menu/type');
  };

  createMenu = async (data) => {
    const token = await this.auth.currentUser.getIdToken();
    console.log(data);
    // return Promise.resolve({data: {id: 3}});
    return axios.post(API_URL + '/menus', data, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  updateMenu = async (data) => {
    const token = await this.auth.currentUser.getIdToken();
    // return Promise.resolve({data: {id: 3}});
    return axios.put(API_URL + '/menus', data, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  deleteMenu = async (id) => {
    const token = await this.auth.currentUser.getIdToken();
    // return Promise.resolve();
    return axios.delete(API_URL + '/menus/' + id, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  copyMenu = async (id, data) => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.post(API_URL + '/menus/copy', data, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  getMenus = async (type) => {
    const token = await this.auth.currentUser.getIdToken();

    // return Promise.resolve({data: [{
    //   id: 3, name: "Жин", type: 0, membership: 0, target: 0, calorie: 1200
    // }]});
    return axios.get(API_URL + '/menus/type/' + type, {
      headers: { Authorization: 'Bearer ' + token }
    });
    // let res = await axios.get("/menus/type/" + type);
    // res.data.map(e => ({
    //   ...e,
    //   locked: 1,
    //   lockedName: "tuul"
    // }));
    // return axios.get("/menus/type/" + type);
  };

  publishMenuToApp = async (id) => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.put(API_URL + `/menus/${id}/publish`, null, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  publishMenu = async (menu) => {
    console.log(menu);
    const documents = await this.db
      .collection('menu')
      .where('menuId', '==', menu.id)
      .limit(1)
      .get();
    if (documents.docs.length == 0) {
      const menuDoc = await this.db.collection('menu').add({
        calorie: menu.calorie,
        membership: menu.membership,
        menuId: menu.id,
        name: menu.name,
        target: menu.target,
        type: menu.type,
        weight: menu.weight
      });
      await menuDoc.update({ uid: menuDoc.id });
    }
  };

  getMenu = async (id) => {
    const token = await this.auth.currentUser.getIdToken();
    // return Promise.resolve({data:
    // [
    //     {
    //       id: 1, food_id: 1, day: 0, meal_plan: 0, name: 'Бөөрөнхий махтай', ref: null, ingredients: [
    //         { id: 1, menu_food_id: 1, food_ingredient_id: 1, ingredient_id: 2, food_id: 1, type: false, size: 20 },
    //         { id: 2, menu_food_id: 2, food_ingredient_id: 2, ingredient_id: 4, food_id: 1, type: false, size: 20 }
    //       ]
    //     },
    //     {
    //       id: 2, food_id: 2, day: 1, meal_plan: 0, name: 'Бөөрөнхий', ref: null, ingredients: [
    //         { id: 3, menu_food_id: 2, food_ingredient_id: 4, ingredient_id: 18, food_id: 2, type: false, size: 20 },
    //         {"food_ingredient_id":33,"menu_food_id":30,"size":10,"type":0}
    //  {"day":6,"food_id":30,"id":16,"ingredients":[,{"food_ingredient_id":34,"menu_food_id":30,"size":10,"type":0}],"meal_plan":0,"menu_id":8,"name":"\u0426\u044d\u043b","ref":null}
    //         { id: 4, menu_food_id: 3, food_ingredient_id: 3, ingredient_id: 21, food_id: 2, type: false, size: 5 }
    //       ]
    //     },
    //     {
    //       id: 3, food_id: 1, day: 1, meal_plan: 0, name: 'Бөөрөнхий махтай', ref: 1, ingredients: []
    //     }
    //   ]
    // });
    return axios.get(API_URL + '/menus/' + id + '/foods', {
      headers: { Authorization: 'Bearer ' + token }
    });
    // const res = await axios.get("/menus/" + id + "/foods",{headers: {"Authorization": "Bearer " + token}});
    // res.data = {
    //   foods: res.data,
    //   locked: null,
    //   name: null
    // }
    // return res;
  };

  lockMenu = async (id) => {
    const token = await this.auth.currentUser.getIdToken();
    // return Promise.resolve({data: {locked: 3,
    //     name: "Tuul"}});
    return axios.get(API_URL + '/menus/lock/' + id, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  unLockMenu = async (id) => {
    const token = await this.auth.currentUser.getIdToken();
    // return Promise.resolve({data: {locked: null,
    //     name: null}});
    return axios.get(API_URL + '/menus/unlock/' + id, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  createMenuFood = async (data, menu_id) => {
    const token = await this.auth.currentUser.getIdToken();
    // return Promise.resolve({data: data});
    return axios.post(API_URL + '/menus/foods/' + menu_id, data, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  updateMenuFood = async (id, data, menu_id) => {
    // return Promise.reject();
    const token = await this.auth.currentUser.getIdToken();
    // return Promise.resolve({data: {...data, id: id}});
    return axios.put(API_URL + '/menus/foods/' + id + '/' + menu_id, data, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  deleteMenuFood = async (id, menu_id) => {
    const token = await this.auth.currentUser.getIdToken();
    // return Promise.resolve({});
    console.log(id);
    return axios.delete(API_URL + '/menus/foods/' + id + '/' + menu_id, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  getUsers = async () => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.get(API_URL + '/users', {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  createUser = async (data) => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.post(API_URL + '/users', data, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  updateUser = async (id, data) => {
    const token = await this.auth.currentUser.getIdToken();
    delete data.role;
    return axios.put(API_URL + '/users/' + id, data, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  updateUserPermission = async (id, data) => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.post(API_URL + '/users/permission/' + id, data, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  getUserPermission = async (id) => {
    return axios.get(API_URL + `/users/${id}/permission`);
  };

  getLogs = async (category, page, query) => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.post(
      API_URL + '/logs',
      { category: category, page: page, query: query },
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  getClients = async (page) => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.get(API_URL + '/customer/' + page, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  searchClient = async (val) => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.post(
      API_URL + '/customer/search',
      { query: val },
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  getClientsWithFilter = async (value) => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.post(API_URL + '/customer/filter', value, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  getClient = async (uid) => {
    const docSnap = await this.db.collection('users').doc(uid).get();
    return docSnap.data();
  };

  getClienstCount = async () => {
    const docSnap = await this.db.collection('analyze').doc('user').get();
    return docSnap.data();
  };

  getClientRegisteredFood = async (uid, bUid) => {
    let foods = [];
    let querySnapshot;
    if (bUid === undefined)
      querySnapshot = await this.db
        .collection('users/' + uid + '/registered_food')
        .orderBy('createdAt', 'desc')
        .limit(30)
        .get();
    else {
      const document = await this.db
        .collection('users/' + uid + '/registered_food')
        .doc(bUid)
        .get();
      querySnapshot = await this.db
        .collection('users/' + uid + '/registered_food')
        .orderBy('createdAt', 'desc')
        .startAfter(document)
        .limit(30)
        .get();
    }
    querySnapshot.forEach((doc) => {
      console.log(doc.id);
      foods.push({ ...doc.data(), uid: doc.id });
    });
    return foods;
  };

  getClientIngredients = async (uid, start, end) => {
    const token = await this.auth.currentUser.getIdToken();
    // return Promise.resolve({data: [
    //     {ingredient_id: 25, size: 40}
    //   ]});
    return axios.post(
      API_URL + '/customer/ingredient',
      { uid, start, end },
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  getClientRegisteredFoodByDay = async (uid, day) => {
    let foods = [];
    let querySnapshot;
    const date = new Date();
    date.setDate(date.getDate() - day);
    date.setHours(0, 0, 0, 0);
    querySnapshot = await this.db
      .collection('users/' + uid + '/registered_food')
      .orderBy('createdAt', 'desc')
      .where('createdAt', '>', date.getTime())
      .get();
    querySnapshot.forEach((doc) => {
      console.log(doc.id);
      foods.push({ ...doc.data(), uid: doc.id });
    });
    return foods;
  };

  getClientRegisteredWater = async (uid, bUid) => {
    let waters = [];
    let querySnapshot;
    if (bUid === undefined)
      querySnapshot = await this.db
        .collection('users/' + uid + '/registered_water')
        .orderBy('createdAt', 'desc')
        .limit(30)
        .get();
    else {
      const document = await this.db
        .collection('users/' + uid + '/registered_water')
        .doc(bUid)
        .get();
      querySnapshot = await this.db
        .collection('users/' + uid + '/registered_water')
        .orderBy('createdAt', 'desc')
        .startAfter(document)
        .limit(30)
        .get();
    }
    querySnapshot.forEach((doc) => {
      waters.push({ ...doc.data(), uid: doc.id });
    });
    return waters;
  };

  getClientRegisteredWaterByDay = async (uid, day) => {
    let waters = [];
    let querySnapshot;
    const date = new Date();
    date.setDate(date.getDate() - day);
    date.setHours(0, 0, 0, 0);
    querySnapshot = await this.db
      .collection('users/' + uid + '/registered_water')
      .orderBy('createdAt', 'desc')
      .where('createdAt', '>', date.getTime())
      .get();
    querySnapshot.forEach((doc) => {
      waters.push({ ...doc.data(), uid: doc.id });
    });
    return waters;
  };

  getClientPayments = async (uid) => {
    const token = await this.auth.currentUser.getIdToken();
    const req = await axios.get(API_URL + '/payments/user/' + uid, {
      headers: { Authorization: 'Bearer ' + token }
    });
    console.log(req.data);
    return req.data;
  };

  getClientWeight = async (uid) => {
    let weights = [];
    const querySnapshot = await this.db
      .collection('users/' + uid + '/weight')
      .orderBy('lastUpdatedAt', 'asc')
      .get();
    querySnapshot.forEach((doc) => {
      weights.push({ ...doc.data(), uid: doc.id });
    });
    return weights;
  };

  getClientRegisteredIngredients = async (uid, bUid) => {
    let ingredients = [];
    let querySnapshot;
    if (bUid === undefined)
      querySnapshot = await this.db
        .collection('users/' + uid + '/ingredients')
        .orderBy('lastUpdatedAt', 'desc')
        .limit(30)
        .get();
    else {
      const document = await this.db
        .collection('users/' + uid + '/ingredients')
        .doc(bUid)
        .get();
      querySnapshot = await this.db
        .collection('users/' + uid + '/ingredients')
        .orderBy('lastUpdatedAt', 'desc')
        .startAfter(document)
        .limit(30)
        .get();
    }
    querySnapshot.forEach((doc) => {
      ingredients.push({ ...doc.data(), uid: doc.id });
    });
    console.log('Ingredients: ', ingredients);
    return ingredients;
  };

  getPayments = async (uid) => {
    const limit = 30;
    let payments = [];
    let querySnapshot;
    if (uid === undefined) {
      querySnapshot = await this.db
        .collection('payment')
        .limit(limit)
        .orderBy('createdAt', 'desc')
        .get();
    } else {
      const document = await this.db.collection('payment').doc(uid).get();
      querySnapshot = await this.db
        .collection('payment')
        .orderBy('createdAt', 'desc')
        .startAfter(document)
        .limit(limit)
        .get();
    }

    querySnapshot.forEach((doc) => {
      payments.push(doc.data());
    });

    return payments;
  };

  getPaymentsWithFilter = async (value) => {
    // return ({
    //   data: [
    //     {
    //       "amount": "24",
    //       "email": null,
    //       "firstname": "Harry",
    //       "id": "qX4FBgZCJstLl80cqA4D",
    //       "lastname": "Nubi",
    //       "phone": "+97699010125",
    //       "plan_code": "PREMIUM",
    //       "response": "\u0410\u043c\u0436\u0438\u043b\u0442\u0442\u0430\u0439",
    //       "status": true,
    //       "type": "social",
    //       "user_date": 1618298553311.0
    //     },
    //     {
    //       "amount": "24",
    //       "email": null,
    //       "firstname": "Erdene-Ochir",
    //       "id": "3FPlvft9NDjb82j5jycz",
    //       "lastname": "Munkhzul",
    //       "phone": "+97680970124",
    //       "plan_code": "PREMIUM",
    //       "response": "SUCCESS",
    //       "status": true,
    //       "type": "qpay",
    //       "user_date": 1618312274605.0
    //     },]
    // })
    const token = await this.auth.currentUser.getIdToken();
    return axios.post(API_URL + '/payments', value, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  searchPayments = async (query) => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.post(
      API_URL + '/payments/search',
      { query: query },
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  getMenuByUserId = async (id) => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.get(API_URL + `/appuser/${id}/plan`, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  getFirebaseMenu = async (id, client) => {
    const doc = await this.db
      .collection('menu')
      .where('menuId', '==', id)
      .get();

    const securityDoc = await this.db
      .collection('users/' + client.uid + '/security')
      .doc('plan')
      .get();

    return {
      menu: doc?.docs[0]?.data() || undefined,
      security: securityDoc.data()
    };
  };

  updateClientMenu = async (id, menu_id, membership, start, end, name) => {
    await this.db
      .collection('users/' + id + '/security')
      .doc('plan')
      .set({
        startDate: start,
        plan: membership,
        endDate: end,
        name: name
      });
    await this.db.collection('users').doc(id).update({
      selectedMenuId: menu_id
    });
    return menu_id;
  };

  getCalculatedMenu = async (data) => {
    let temp =
      Math.pow(data.height / 100, 2) * (data.gender === 'male' ? 22 : 21);

    let actual;
    if (data.weight > data.targetWeight) actual = temp * 25;
    else actual = 66.5 + 13.75 * temp + 5.003 * data.height - 6.755 * data.age;
    console.log('Calorie:', actual);

    let weight = data.weight;

    let roundedActual = Math.floor(actual / 100) * 100;
    if (roundedActual > 2000) roundedActual = 2000;
    else if (roundedActual < 1200) roundedActual = 1200;

    const menuDocs = await this.db
      .collection('menu')
      // .where('type', '==', 2)
      .where('calorie', '==', roundedActual)
      .get();

    if (menuDocs.docs.length === 0) {
      return [];
    }
    console.log(menuDocs.docs.length);

    let filteredWithWeight = [];
    menuDocs.docs.forEach((menu) => {
      if (weight >= menu.data().weight && menu.data().target == 0)
        filteredWithWeight.push(menu.data());
      else if (weight < menu.data().weight && menu.data().target == 1)
        filteredWithWeight.push(menu.data());
    });

    console.log(filteredWithWeight.length);

    const randomIndex = Math.floor(Math.random() * filteredWithWeight.length);
    console.log('_______________________Random index____', randomIndex);
    const menuData = filteredWithWeight[randomIndex];
    console.log(menuData);
    // await db.doc(`users/${userUid}`).set({selectedMenuUid: menuData.uid}, {merge: true});
    return [...filteredWithWeight];
  };

  getCalculatedLazyMenuFromSystem = async (data) => {
    const token = await this.auth.currentUser.getIdToken();

    let temp =
      Math.pow(data.height / 100, 2) * (data.gender === 'male' ? 22 : 21);
    let req;
    let menuDocs;
    let actual;
    let filteredWithWeight;
    let rangeValue;
    let type;
    /*
    Odoo bga jin n zoriltot jingeesee ikh jin buuruulakh
    Odoo bga jin n zoriltot jingeesee baga bol jin nemne
    Odoo bga n tentsuu bol jin barina eswel 1 zuruutei bvl jin barina
     */
    req = await axios.get(API_URL + '/menus/type/35', {
      headers: { Authorization: 'Bearer ' + token }
    });

    if (data.age >= 18 && data.age <= 35) rangeValue = 2;
    else if (data.age >= 36 && data.age <= 45) rangeValue = 3;
    else rangeValue = 5;

    if (temp + rangeValue >= data.weight) {
      // Weight maintain
      type = 'Жин барих';
      let calorie_per_day = 0;

      calorie_per_day = data.weight * 35;
      let roundedActual = 0;
      roundedActual = Math.floor(calorie_per_day / 100) * 100;
      if (roundedActual > 2200) roundedActual = 2200;
      else if (roundedActual < 1200) roundedActual = 1200;
      console.log(roundedActual);
      console.log(req.data);
      menuDocs = req.data.filter((e) => e.calorie === roundedActual);
    } else {
      type = 'Жин бууруулах';
      actual = 66.5 + 13.75 * temp + 5.003 * data.height - 6.755 * data.age;
      let bmi = data.weight / Math.pow(data.height / 100, 2);
      if (bmi >= 30 && bmi < 35) {
        actual += 300;
      } else if (bmi >= 35 && bmi < 40) {
        actual += 400;
      } else if (bmi >= 40) {
        actual += 500;
      }
      let roundedActual = Math.floor(actual / 100) * 100;
      if (roundedActual > 2200) roundedActual = 2200;
      else if (roundedActual < 1200) roundedActual = 1200;
      console.log(roundedActual);
      console.log(req.data);
      menuDocs = req.data.filter((e) => e.calorie === roundedActual);
    }

    if (menuDocs.length === 0) {
      return { type: 'Олдсонгүй', data: [] };
    }

    console.log(data.fromWeightGain);
    menuDocs = menuDocs.filter((e) => e.name === data.fromWeightGain);

    return {
      type: type + ' (Байх ёстой ' + temp + 'кг )',
      data: [...menuDocs]
    };
  };

  getCalculatedDetoxFromSystem = async (data) => {
    const token = await this.auth.currentUser.getIdToken();

    let temp =
      Math.pow(data.height / 100, 2) * (data.gender === 'male' ? 22 : 21);
    let req;
    let menuDocs;
    let actual;
    let type;

    type = 'Детокс';
    req = await axios.get(API_URL + '/menus/type/77', {
      headers: { Authorization: 'Bearer ' + token }
    });
    actual = 66.5 + 13.75 * temp + 5.003 * data.height - 6.755 * data.age;
    let bmi = data.weight / Math.pow(data.height / 100, 2);
    if (bmi < 21) return { type: 'Боломжгүй', data: [] };
    if (bmi >= 30 && bmi < 35) {
      actual += 200;
    } else if (bmi >= 35 && bmi < 40) {
      actual += 300;
    } else if (bmi >= 40) {
      actual += 400;
    }
    let roundedActual = Math.floor(actual / 100) * 100;
    if (roundedActual > 2200) roundedActual = 2200;
    else if (roundedActual < 1200) roundedActual = 1200;
    menuDocs = req.data.filter(
      (e) => e.calorie === roundedActual && e.membership === 0
    );

    const conditions = [
      {
        names: ['Сонголт-1'],
        bmiMin: 25.9,
        bmiMax: 100,
        ageMin: 30,
        ageMax: 45
      },
      {
        names: ['Сонголт-2', 'Сонголт-3', 'Сонголт-4', 'Сонголт-6'],
        bmiMin: 20,
        bmiMax: 24.9,
        ageMin: 0,
        ageMax: 45
      },
      {
        names: ['Сонголт-2', 'Сонголт-3', 'Сонголт-4', 'Сонголт-6'],
        bmiMin: 25,
        bmiMax: 29,
        ageMin: 45,
        ageMax: 100
      },
      {
        names: ['Сонголт-5'],
        bmiMin: 20,
        bmiMax: 24.9,
        ageMin: 45,
        ageMax: 100
      },
      {
        names: ['Сонголт-7', 'Сонголт-10', 'Сонголт-11', 'Сонголт-12'],
        bmiMin: 28,
        bmiMax: 100,
        ageMin: 0,
        ageMax: 40
      },
      { names: ['Сонголт-8'], bmiMin: 25, bmiMax: 28, ageMin: 40, ageMax: 50 },
      { names: ['Сонголт-9'], bmiMin: 22, bmiMax: 27, ageMin: 0, ageMax: 40 }
    ];

    const filteredConditions = conditions
      .filter((e) => e.bmiMin <= bmi && e.bmiMax >= bmi)
      .filter((e) => e.ageMin <= data.age && e.ageMax >= data.age);
    console.log(filteredConditions);
    let names = {};
    filteredConditions.forEach((condition) => {
      condition.names.forEach((name) => {
        names[name] = '';
      });
    });

    const namesArr = Object.keys(names);
    console.log(namesArr);
    console.log(menuDocs);

    const result = menuDocs.filter(
      (e) => namesArr.find((val) => val === e.name) !== undefined
    );
    console.log(result);

    return {
      type: type + ' (BMI ' + bmi + 'кг )',
      data: [...result]
    };
  };

  getCalculatedCovidFromSystem = async (data) => {
    const token = await this.auth.currentUser.getIdToken();
    if (!(data.afterCovid || data.duringCovid)) {
      return {
        type: 'Ковид өгөгдөл дутуу',
        data: []
      };
    }

    let temp =
      Math.pow(data.height / 100, 2) * (data.gender === 'male' ? 22 : 21);
    let req;
    let menuDocs;
    let actual;
    let type;
    let namesArr = [];
    let conditions = [];
    actual = 66.5 + 13.75 * temp + 5.003 * data.height - 6.755 * data.age;
    let bmi = data.weight / Math.pow(data.height / 100, 2);
    if (data.covid === 'yes') {
      conditions = [
        ['Сонголт-1', 'Сонголт-2', 'Сонголт-3'],
        ['Сонголт-4', 'Сонголт-5', 'Сонголт-6']
      ];
      if (data.afterCovid === 'yes') {
        namesArr = conditions[0];
        actual += 200;
      } else {
        namesArr = conditions[1];
      }
      type = 'Ковид-н дараа';
      req = await axios.get(API_URL + '/menus/type/79', {
        headers: { Authorization: 'Bearer ' + token }
      });
    } else {
      conditions = [
        ['Сонголт-4', 'Сонголт-5', 'Сонголт-6'],
        ['Сонголт-1', 'Сонголт-2', 'Сонголт-3']
      ];
      if (data.afterCovid === 'yes') {
        namesArr = conditions[0];
      } else {
        namesArr = conditions[1];
      }
      type = 'Ковид-н үед';
      req = await axios.get(API_URL + '/menus/type/80', {
        headers: { Authorization: 'Bearer ' + token }
      });
    }

    let roundedActual = Math.floor(actual / 100) * 100;
    if (roundedActual > 2200) roundedActual = 2200;
    else if (roundedActual < 1200) roundedActual = 1200;
    menuDocs = req.data.filter(
      (e) =>
        e.calorie === roundedActual &&
        e.membership === 0 &&
        namesArr.includes(e.name)
    );

    return {
      type: type + ' (BMI ' + bmi + 'кг )',
      data: [...menuDocs]
    };
  };

  getCalculatedMenuFromSystem = async (data) => {
    const token = await this.auth.currentUser.getIdToken();

    let temp =
      Math.pow(data.height / 100, 2) * (data.gender === 'male' ? 22 : 21);
    let req;
    let menuDocs;
    let actual;
    let filteredWithWeight;
    let rangeValue;
    let type;
    /*
    Odoo bga jin n zoriltot jingeesee ikh jin buuruulakh
    Odoo bga jin n zoriltot jingeesee baga bol jin nemne
    Odoo bga n tentsuu bol jin barina eswel 1 zuruutei bvl jin barina
     */

    if (data.age >= 18 && data.age <= 35) rangeValue = 2;
    else if (data.age >= 36 && data.age <= 45) rangeValue = 3;
    else rangeValue = 5;
    if (temp - rangeValue <= data.weight && temp + rangeValue >= data.weight) {
      // Weight maintain
      type = 'Жин барих';
      req = await axios.get(API_URL + '/menus/type/4', {
        headers: { Authorization: 'Bearer ' + token }
      });

      let calorie_per_day = 0;

      if (data.fromWeightGain) {
        calorie_per_day = data.weight * 35;
      } else {
        calorie_per_day = data.weight * 23;
      }
      let roundedActual = 0;
      if (data.fromWeightGain) {
        // From weight gain menu
        console.log('From weight gain menu');
        const calories = [1750, 2100, 2600, 3100, 3600, 4100];

        for (let i = 0; i < calories.length; i++)
          if (calorie_per_day > calories[i] - 100) roundedActual = calories[i];
          else break;
        if (roundedActual < calories[0]) roundedActual = calories[0];
      } else {
        // From weight loss menu
        console.log('From loss gain menu');
        roundedActual = Math.floor(calorie_per_day / 100) * 100;
        if (roundedActual > 2000) roundedActual = 2000;
        else if (roundedActual < 1200) roundedActual = 1200;
      }
      menuDocs = req.data.filter(
        (e) => e.calorie === roundedActual && e.membership === 0
      );
    } else if (data.weight < temp) {
      type = 'Жин нэмэх';
      req = await axios.get(API_URL + '/menus/type/3', {
        headers: { Authorization: 'Bearer ' + token }
      });
      actual = temp * 35 + 500;
      const calories = [1750, 2100, 2600, 3100, 3600, 4100];
      let roundedActual = 0;
      for (let i = 0; i < calories.length; i++)
        if (actual > calories[i] - 100) roundedActual = calories[i];
        else break;
      if (roundedActual < calories[0]) roundedActual = calories[0];
      menuDocs = req.data.filter(
        (e) => e.calorie === roundedActual && e.membership === 0
      );
    } else {
      type = 'Жин бууруулах';
      req = await axios.get(API_URL + '/menus/type/2', {
        headers: { Authorization: 'Bearer ' + token }
      });
      actual = Math.floor(temp * 25);
      let bmi = data.weight / Math.pow(data.height / 100, 2);
      if (bmi >= 30 && bmi < 35) {
        actual += 300;
      } else if (bmi >= 35 && bmi < 40) {
        actual += 400;
      } else if (bmi >= 40) {
        actual += 500;
      }
      let roundedActual = Math.floor(actual / 100) * 100;
      if (roundedActual > 2000) roundedActual = 2000;
      else if (roundedActual < 1200) roundedActual = 1200;
      menuDocs = req.data.filter(
        (e) => e.calorie === roundedActual && e.membership === 0
      );
    }

    filteredWithWeight = [{ weight: -30 }, { weight: -30 }, { weight: -30 }];
    menuDocs.forEach((menu) => {
      let weightDiff = Math.abs(menu.weight - data.weight);
      for (let i = 0; i < filteredWithWeight.length; i++) {
        let currentWeightDifference = Math.abs(
          filteredWithWeight[i].weight - data.weight
        );
        if (weightDiff < currentWeightDifference) {
          filteredWithWeight[i] = menu;
          break;
        }
      }
    });

    if (req.data.length === 0) {
      return { type: 'Олдсонгүй', data: [] };
    }

    console.log(menuDocs);

    // const limit = 2;
    // menuDocs.forEach(menu => {
    //   if(weight >= menu.weight && menu.target === 0) filteredWithWeight.push(menu)
    //   else if(weight < menu.weight && menu.target === 1) filteredWithWeight.push(menu)
    // })
    // const randomIndex = Math.floor(Math.random() * filteredWithWeight.length)
    // console.log('_______________________Random index____', randomIndex)
    // const menuData = filteredWithWeight[randomIndex];
    // console.log(menuData)
    // await db.doc(`users/${userUid}`).set({selectedMenuUid: menuData.uid}, {merge: true});
    return {
      type: type + ' (Байх ёстой ' + temp + 'кг )',
      data: [...filteredWithWeight]
    };
  };

  getPaymentAnalytics = async () => {
    const token = await this.auth.currentUser.getIdToken();
    return axios.get(API_URL + '/payments/report', {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  getAllergies = async () => {
    let allergies = [];
    const querySnapshot = await this.db.collection('allergy').get();
    querySnapshot.forEach((doc) => {
      allergies.push({ ...doc.data(), uid: doc.id, checked: false });
    });
    return allergies;
  };

  createNavbarMenu = async (body) => {
    const token = await this.auth.currentUser.getIdToken();
    await axios.post(API_URL + '/menus/types', body, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };
  editNavbarMenu = async (id, body) => {
    const token = await this.auth.currentUser.getIdToken();
    await axios.put(API_URL + '/menus/types/' + id, body, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  test = async () => {
    const token = await this.auth.currentUser.getIdToken();
    const res = await axios.post(
      API_URL + '/appuser/MBR61vHkIaYtkbQEDNe1gZu4oOW2',
      { weight_now: 60, walk_count: 42132 },
      { headers: { Authorization: 'Bearer ' + token } }
    );
    console.log('Test:', res.data);
  };

  getMealPlans = async () => {
    const token = await this.auth.currentUser.getIdToken();
    return Promise.resolve({
      data: [
        { id: 1, name: 'Өглөөний цай' },
        { id: 2, name: 'Хөнгөн хоол' },
        { id: 3, name: 'Өдрийн хоол' },
        { id: 4, name: 'Хөнгөн зууш' },
        { id: 5, name: 'Унтхын өмнө' }
      ]
    });
    return axios.get(API_URL + '/meal_plans', {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  createMealPlan = async (name) => {
    const token = await this.auth.currentUser.getIdToken();
    return Promise.resolve({
      data: { id: 3, name: name }
    });
  };

  updateMealPlan = async (id, name) => {
    const token = await this.auth.currentUser.getIdToken();
    return Promise.resolve({
      data: { id: id, name: name }
    });
  };

  getTypeMealPlans = async (id) => {
    const token = await this.auth.currentUser.getIdToken();
    return Promise.resolve({
      data: [
        { id: 1, type_id: 1, meal_plan_id: 1, order: 1 },
        { id: 2, type_id: 1, meal_plan_id: 2, order: 2 },
        { id: 3, type_id: 1, meal_plan_id: 3, order: 3 },
        { id: 4, type_id: 1, meal_plan_id: 4, order: 4 },
        { id: 5, type_id: 1, meal_plan_id: 5, order: 5 }
      ]
    });
  };

  swapTypeMealPlan = async (dataX, dataY) => {
    const token = await this.auth.currentUser.getIdToken();
    return Promise.resolve({
      data: {
        dataX: { ...dataX, order: dataY.order },
        dataY: { ...dataY, order: dataX.order }
      }
    });
  };

  createTypeMealPlan = async (data) => {
    const token = await this.auth.currentUser.getIdToken();
    return Promise.resolve({
      data: {
        id: 6,
        ...data
      }
    });
  };

  getMenuPermissions = async (id) => {
    const token = await this.auth.currentUser.getIdToken();
    // return Promise.resolve({
    //   data: [
    //     {
    //       id: 6,
    //       type_id: 4,
    //       permission: false,
    //       user_id: id
    //     },
    //   ]
    // });
    return axios.get(API_URL + '/users/permission/' + id, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  addMenuPermission = async (type, user_id) => {
    const token = await this.auth.currentUser.getIdToken();
    // return Promise.resolve({
    //   data: {
    //     id: 6,
    //     type_id: type.id,
    //     permission: false,
    //     user_id: 1
    //   },
    // })
    console.log(user_id);
    return axios.post(
      API_URL + '/users/permission',
      {
        user_id: user_id,
        type_id: type.id,
        permission: false
      },
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };

  deleteMenuPermission = async (id) => {
    const token = await this.auth.currentUser.getIdToken();
    // return Promise.resolve({
    //   data: {
    //     id: id,
    //   },
    // })
    return axios.delete(API_URL + '/users/permission/' + id, {
      headers: { Authorization: 'Bearer ' + token }
    });
  };

  setMenuPermission = async (id, permission) => {
    const token = await this.auth.currentUser.getIdToken();
    // return Promise.resolve({
    //   data: {
    //     id: id,
    //     permission: permission,
    //   },
    // })
    return axios.put(
      API_URL + '/users/permission/' + id,
      {
        permission: permission
      },
      { headers: { Authorization: 'Bearer ' + token } }
    );
  };
}

export default Firebase;
