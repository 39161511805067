import { CircularProgress } from '@mui/material';
import React from 'react';

export default function Loading() {
  return (
    <div
      style={{
        height: '100vh',
        display: 'grid',
        placeItems: 'center'
      }}
    >
      <CircularProgress />
    </div>
  );
}
