import * as actionTypes from 'src/actions';

const initialState = {
  loggedIn: false,
  user: null
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      return {
        ...initialState,
        user: action.user
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        user: null
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
