import React, { useEffect } from 'react';
import { withFirebase } from '../Firebase';
import { connect } from 'react-redux';
import { compose } from 'react-recompose';
import { login } from '../../actions';

const withAuthentication = (Component) => {
  const WithAuthentication = (props) => {
    useEffect(() => {
      props.firebase.stateSessionPersist().then(() => {
        const listener = props.firebase.auth.onAuthStateChanged(
          async (authUser) => {
            if (authUser) {
              const token = await authUser.getIdTokenResult();

              const userPermissionData = await props.firebase.getUserPermission(
                token.claims.id
              );
              token.claims.permissions = userPermissionData.data
                .permissions || {
                // token.claims.permissions = {
                user: [true, false],
                client: [false, true],
                payment: [true, false],
                ingredient: [false, true],
                food: [false, true],
                menu: [true, false],
                download: [false, false],
                course: [false, false],
                coursecategory: [false, false]
              };
              token.claims.menu_permissions = userPermissionData.data
                .menu_permissions || [
                {
                  id: 6,
                  type_id: 4,
                  permission: true,
                  user_id: 1
                }
              ];
              props.onSetAuthUser(token.claims);
            } else {
              console.log('!Authenticated');
              props.onSetAuthUser(null);
            }
          }
        );
        return () => {
          listener();
        };
      });
    }, [props]);

    return <Component {...props} />;
  };

  const mapDispatchToProps = (dispatch) => ({
    onSetAuthUser: (authUser) => {
      dispatch(login(authUser));
    }
  });

  return compose(
    withFirebase,
    connect(null, mapDispatchToProps)
  )(WithAuthentication);
};

export default withAuthentication;
