/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
const Menus = lazy(() => import('src/views/Menus'));
const Menu = lazy(() => import('src/views/Menus/Menu'));
const Calculator = lazy(() => import('src/views/Calculator'));
const MenuSelectionCalculator = lazy(() =>
  import('src/views/MenuSelectionCalculator')
);
const MenuDetoxSelectionCalculator = lazy(() =>
  import('src/views/MenuDetoxSelectionCalculator')
);
const MenuCovidSelectionCalculator = lazy(() =>
  import('src/views/MenuCovidSelectionCalculator')
);
const MenuLazySelectionCalculator = lazy(() =>
  import('src/views/MenuLazySelectionCalculator')
);
const Logs = lazy(() => import('src/views/Logs'));
const MenuSettings = lazy(() => import('src/views/MenuSettings'));
const AuthLayout = lazy(() => import('src/layouts/Auth'));
const ErrorLayout = lazy(() => import('src/layouts/Error'));
const DashboardLayout = lazy(() => import('src/layouts/Dashboard'));
const Ingredient = lazy(() => import('src/views/Ingredient'));
const Courses = lazy (() => import ('src/views/Courses'));
const CourseCategories = lazy (() => import ('src/views/CourseCategories'));
const GettingStarted = lazy(() => import('src/views/GettingStarted'));
const FreeFoods = lazy(() => import('src/views/FreeFoods'));
const Foods = lazy(() => import('src/views/Foods'));
const Mealplans = lazy(() => import('src/views/Mealplans'));
const Users = lazy(() => import('src/views/Users'));
const Clients = lazy(() => import('src/views/Clients'));
const Profile = lazy(() => import('src/views/Clients/Profile'));
const Types = lazy(() => import('src/views/Types'));
const Payments = lazy(() => import('src/views/Payments'));
// const CreateFood = lazy(() => import('src/views/Foods/CreateFood'));
const EditFood = lazy(() => import('src/views/Foods/EditFood'));
const Login = lazy(() => import('src/views/Login'));
const Error401 = lazy(() => import('src/views/Error401'));
const Error404 = lazy(() => import('src/views/Error404'));
const Error500 = lazy(() => import('src/views/Error500'));
const PaymentAnalytics = lazy(() => import('src/views/PaymentAnalytics'));
const routes = [
  {
    path: '/',
    element: <Navigate to="/foods" />
  },
  {
    path: '/auth',
    element: <AuthLayout />,
    children: [
      {
        path: 'login',
        element: <Login />
      }
      // {
      //   path: '/auth/register',
      //   element: lazy(() => import('src/views/Register'))
      // },
      // {
      //   element: () => <Navigate to="/errors/error-404" />
      // }
    ]
  },
  {
    path: '/errors',
    element: <ErrorLayout />,
    children: [
      {
        path: 'error-401',
        element: <Error401 />
      },
      {
        path: 'error-404',
        element: <Error404 />
      },
      {
        path: 'error-500',

        element: <Error500 />
      }
      // {
      //   element: () => <Navigate to="/errors/error-404" />
      // }
    ]
  },
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      //   {
      //     path: '/others',
      //
      //
      //     element: lazy(() => import('src/views/Mealplans'))
      //   },
      //   {
      //     path: '/others/mealplans',
      //
      //
      //     element: lazy(() => import('src/views/Mealplans'))
      //   },
      {
        path: '/payments/analytics',
        element: <PaymentAnalytics />
      },
      {
        path: '/ingredient',
        element: <Ingredient />
      },
      {
        path: '/courses',
        element: <Courses />
      },
      {
        path: '/coursecategories',
        element: <CourseCategories/>
      },
      {
        path: '/start',
        element: <GettingStarted />
      },
      {
        path: '/foods',
        element: <Foods />
      },
      {
        path: '/free',
        element: <FreeFoods />
      },
      {
        path: '/meal_plans',
        element: <Mealplans />
      },
      {
        path: '/users',
        element: <Users />
      },
      {
        path: '/clients',
        element: <Clients />
      },
      {
        path: '/clients/:id',
        element: <Profile />
      },
      // {
      //   path: '/clients2/:id',
      //   element: <Client />
      // },
      {
        path: '/types',
        element: <Types />
      },
      {
        path: '/payments',
        element: <Payments />
      },
      // {
      //   path: '/foods/create',
      //   element: <CreateFood />
      // },
      {
        path: '/foods/:id',
        element: <EditFood />
      },
      {
        path: '/menus',
        element: <Menus />
      },
      {
        path: '/menus/type/:id',
        element: <Menus />
      },
      {
        path: '/menus/:id',
        element: <Menu />
      },
      {
        path: '/settings/menu',
        element: <MenuSettings />
      },
      {
        path: '/calculator',
        element: <Calculator />
      },
      {
        path: '/calculator/menu',
        element: <MenuSelectionCalculator />
      },
      {
        path: '/calculator/menu/detox',
        element: <MenuDetoxSelectionCalculator />
      },
      {
        path: '/calculator/menu/covid',
        element: <MenuCovidSelectionCalculator />
      },
      {
        path: '/calculator/menu/lazy',
        element: <MenuLazySelectionCalculator />
      },
      {
        path: '/logs',
        element: <Logs />
      },
      {
        path: '*',
        element: <Navigate to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
