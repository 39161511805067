import 'react-perfect-scrollbar/dist/css/styles.css';
import { useRoutes } from 'react-router-dom';

import routes from './routes';

import './assets/scss/main.scss';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/prismjs';
import './mixins/validate';
import './mock';
import withAuthentication from './service/Session/withAuthentication';

function App() {
  let element = useRoutes(routes);
  return element;
}

export default withAuthentication(App);
